import Header from '../../Header.vue'
import Footer from '../../Footer.vue'
import UserLeft from '../PersonUserLeft.vue'
import CheckInService from '../../../api/CheckInService.js'

export default{
	name:'PerUserSafe',
	data(){
		return{
			userInfo:'',//用户信息
			userPhone:'',//手机号
		}
	},
	methods:{
		GetUserInfo(){ //获取用户信息
			var that = this
			if(that.$userType == 0){
				CheckInService.GetUserPerInfo(that.$UserID).then(res=>{
					if(res.Tag == 1){
						that.userInfo = res.Result
						that.userPhone = that.userInfo.Tel.replace(that.userInfo.Tel.substring(3, that.userInfo.Tel.length - 3), '*****')
					}
				})
			}else{
				CheckInService.GetUserOrgInfo(that.$UserID).then(res=>{
					if(res.Tag == 1){
						that.userInfo = res.Result
						that.userPhone = that.userInfo.Tel.replace(that.userInfo.Tel.substring(3, that.userInfo.Tel.length - 3), '*****')
					}
				})
			}
			
		},
		EditPassword(){ //修改密码
			this.$router.push({
				path:'EditPerPassword'
			})
		},
		SetUserEmail(){ //绑定或修改邮箱
			this.$router.push({
				path:'/EditPerEmail'
			})
		},
		SetUserPhone(){ //修改手机号
			this.$router.push({
				path:'/EditPerPhone'
			})
		}
	},
	created(){
		this.GetUserInfo()
	},
	components:{
		'Header':Header,
		'Footer':Footer,
		'UserLeft':UserLeft
	}
}